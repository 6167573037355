import React from 'react';

import {
  Breadcrumb,
  Breadcrumbs,
  DevtoolsWrapper,
  InlineSpinner,
} from '@/components';

const example = () => {
  return (
    <DevtoolsWrapper>
      <Breadcrumbs>
        <Breadcrumb>First</Breadcrumb>
        <Breadcrumb>Second</Breadcrumb>
        <Breadcrumb>
          <a href="#some-link">Link</a>
        </Breadcrumb>
        <Breadcrumb>
          <InlineSpinner />
        </Breadcrumb>
        <Breadcrumb last>Last</Breadcrumb>
      </Breadcrumbs>
    </DevtoolsWrapper>
  );
};

export default example;
